body,
body a {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #EBEBEB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid {
  max-width: 1000px;
  margin: 0 auto;
}


.home .logo {
}

.motto {
  display: block;
  padding: .5rem 1rem;
  font-size: 1.25em;
  font-family: Georgia, serif;
  text-align: center;
  border-top: solid 2px #636161;
  border-bottom: solid 2px #636161;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  letter-spacing: .25em;
}

.home .card {
  border: none;
  background: transparent;
  margin-bottom: 1.25em;
}

.home .card a{
  color: inherit;
}

.home .card-body {
  padding: 0;
}

.home .card .card-title {
  font-weight: bold;
  font-size: 1.5em;
}

nav.navbar {
  font-weight: bold;
  font-size: 1.25em;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
nav .navbar-nav {
  border-top: solid 2px #636161;
  border-bottom: solid 2px #636161;
  margin-top: 25px;
  width: 100%;
}
body .navbar-light .navbar-nav .nav-link a {
  color: black;
}

nav a {
  margin-right: 2em;
}
nav a:last-child {
  margin-right: 0;

}



nav .logo {
  width: 200px;
  float: left;
  padding: 0;
  margin: 0;

}

.product {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  padding: 1.25rem;
  margin-bottom: 1em;
}

/* Sketchbook */

.sketchbook .image-gallery-left-nav,
.sketchbook .image-gallery-right-nav {
  top:110%
}
